.App {
    text-align: center;
    font-family: "Poppins", "Noto Sans", sans-serif;
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.logo-image-button {
    margin-left: 1em;
    margin-right: 1em;
}

.logo-image {
    width: 48px;
}

.pre-info {
    white-space: pre-wrap;
}

.wc-card {
    min-width: 275px;
    margin: 16px 8px;
}

.profile-icon-button {
    padding: 0;
    flex-direction: column;
}

.user-flag {
    margin-left: 20px;
}

.user-flag-flipped {
    margin-right: 20px;
}

.team-points {
    margin-left: 20px;
}

.party-card-row {
    cursor: pointer;
}

.eliminated {
    -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
    filter: grayscale(100%);
}
